//https://tailwindui.com/components/ecommerce/components/product-overviews // With tabs

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import { StarIcon } from '@heroicons/react/20/solid'
import { Tab } from '@headlessui/react'

import WeddingBandDescription from './descriptions/WeddingBandDescription'
import EngagementRingsDescription from './descriptions/EngagementRingsDescription'
import DateNightDescription from './descriptions/DateNightDescription'
import CoinRingsDescription from './descriptions/CoinRingsDescription'
import GirlsNightDescription from './descriptions/GirlsNightDescription'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Tabs = ({ reviews, name }) => {
  const DescriptionMapping = {
    "Wedding Bands" : WeddingBandDescription,
    "Engagement Rings" : EngagementRingsDescription,
    "Date Night" : DateNightDescription,
    "DIY Coin Rings" : CoinRingsDescription,
    "Girl's Night" : GirlsNightDescription,
  }

  const DescriptionComponent = DescriptionMapping[name];
  console.log({name});
  console.log({DescriptionComponent})

  return (
    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
      <Tab.Group as="div">
        <div className="border-b border-gray-200">
          <Tab.List className="-mb-px flex space-x-8">
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                )
              }
            >
              Description
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium'
                )
              }
            >
              Customer Reviews
            </Tab>
          </Tab.List>
        </div>
        <Tab.Panels as={Fragment}>
          <Tab.Panel className="text-sm text-gray-500">
            <h3 className="sr-only">Description</h3>
            {DescriptionComponent ? <DescriptionComponent /> : <p>No description available.</p>}
          </Tab.Panel>

          <Tab.Panel className="-mb-10">


            <h3 className="sr-only">Customer Reviews</h3>

            {reviews.all.map((review, reviewIdx) => (
              <div key={review.id} className="flex space-x-4 text-sm text-gray-500">
                <div className={classNames(reviewIdx === 0 ? '' : 'border-t border-gray-200', 'py-10')}>
                  <h3 className="font-medium text-gray-900">{review.author}</h3>
                  <p>
                    <time dateTime={review.datetime}>{review.date}</time>
                  </p>

                  <div className="mt-4 flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          review.rating > rating ? 'text-yellow-400' : 'text-gray-300',
                          'h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="sr-only">{review.rating} out of 5 stars</p>

                  <div
                    className="prose prose-sm mt-4 max-w-none text-gray-500"
                    dangerouslySetInnerHTML={{ __html: review.content }}
                  />
                </div>
              </div>
            ))}
          </Tab.Panel>

        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Tabs;
