// https://tailwindui.com/components/ecommerce/components/product-overviews // With image grid

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    theme: {
      extend: {
        gridTemplateRows: {
          '[auto,auto,1fr]': 'auto auto 1fr',
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useState } from 'react'
import Tabs from '../components/Tabs'
import ImageGallery from '../components/ImageGallery'

import React from 'react';
import ClassEventButtons from '../components/ClassEventButtons'
import BookingNav from '../components/BookingNav'
import OptionsForm from '../components/OptionsForm';
import CalltoBook from '../components/CalltoBook';

const BookingPage = ({bookingOptions, pageTitle}) => {
  const [selectedBooking, setSelectedBooking] = useState(bookingOptions[0])

  return (
    <div className="bg-white">
      <div className="pt-6">

        <BookingNav selectedBooking={selectedBooking} />

        <ClassEventButtons bookingOptions={bookingOptions} setSelectedBooking={setSelectedBooking} selectedBooking={selectedBooking}/>

        <ImageGallery images={selectedBooking.images} />

        {/* Product info */}
        <div className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{pageTitle}</h1>
            <h2 className="text-l font-bold tracking-tight text-gray-800 sm:text-xl">{selectedBooking.name}</h2>
          </div>

          {/* Options */}
          <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl tracking-tight text-gray-900">{selectedBooking.price}</p>
            <p className="text-l tracking-tight text-gray-600">{selectedBooking.priceSubtext}</p>

            {/* Reviews */}
            {/* <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating ? 'text-gray-900' : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.all.length} reviews
                </a>
              </div>
            </div> */}

            {/* <OptionsForm selectedBooking={selectedBooking} /> */}
            <CalltoBook />

          </div>

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            <Tabs reviews={selectedBooking.reviews} name={selectedBooking.name}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingPage;