import React from 'react';

const ClassEventButtons = ({ bookingOptions, setSelectedBooking, selectedBooking }) => {
  return (
    <div className="max-w-2xl mx-auto sm:px-6 pt-6 grid-cols-1 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:gap-y-3 lg:px-8">
      {bookingOptions.map((bookingClass) => (
        <button
          key={bookingClass.name}
          className={`cursor-pointer bg-white text-gray-900 shadow-sm group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6 ${
            bookingClass === selectedBooking ? 'ring-2 ring-indigo-500' : ''
          }`}
          onClick={() => setSelectedBooking(bookingClass)}
        >
          {bookingClass.name}
        </button>
      ))}
    </div>
  );
};

export default ClassEventButtons;
