import { useState } from "react";
import headerImg from "../assets/header.jpg";
import Tabs from "../components/Tabs"

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-gray-900">
      <div className="relative h-0 overflow-hidden pt-[80%]">
        <img
          src={headerImg}
          alt="Header"
          className="absolute inset-0 z-0 h-full w-full object-cover"
        />
      </div>
    </div>
  );
}
