// Marketing / Page Sections/ Footers/ 4-column with company mission on dark

import LightLogo from "../assets/dark-logo.png";

const navigation = {
  social: [
    {
      name: "Instagram",
      href: "https://instagram.com/diyhandmaderings?igshid=YmMyMTA2M2Y=",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Etsy",
      href: "https://www.etsy.com/shop/DIYHandmadeRings?ref=simple-shop-header-name&listing_id=575901631",
      icon: (props) => (
        <svg viewBox="0 0 60 60" {...props}>
          <text
            x="50%"
            y="50%"
            dy="0.15em"
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="Montserrat"
            fontWeight="bold"
            fontSize="60"
            fill="currentColor"
          >
            E
          </text>
        </svg>
      ),
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/diyhandmaderings",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Example() {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-4 pt-16 sm:pt-24 lg:px-8 lg:pt-32 flex flex-col lg:flex-row">
        <div className="lg:w-1/3 lg:pr-8">
          <img className="h-21" src={LightLogo} alt="Company name" />
          <p className="text-sm leading-6 text-gray-300 mt-4">
            DIYrings@Gmail.com
          </p>
          <p className="text-sm leading-6 text-gray-300 mb-4">
            Telephone - 801-497-1390
          </p>
        </div>
        <div className="lg:w-2/3">
          <p className="text-sm leading-6 text-gray-300">
            At The DIY Handmade Rings Studio Clients Can Book Hands On Classes
            Where They Make Their Engagement Rings Or Wedding Bands. Students
            Start With A Hunk Of Metal And Walk Out With A Finished Piece Of
            Jewelry. We Also Host Ladies Nights, Date Nights, And Fun
            Get-Togethers At Our Studio Where Clients Make A Set Of Earrings Or
            Other Jewelry. Can’t Come To Us? We’ll Come To You! Book Us For Your
            Next Corporate Event, Wedding, Or Party. Custom Order Piece Are Also
            Available For Those Creative Folks Who Have A Cool Idea But Don’t
            Feel Like Getting Their Hands Dirty.
          </p>
        </div>
      </div>
      <div className="mt-4 lg:mt-4">
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-8 sm:pt-12 lg:px-8">
          <div className="flex justify-center space-x-6">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-500 hover:text-gray-400"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-white/10 pt-4 sm:mt-2 lg:mt-4">
        <p className="text-xs leading-5 text-gray-400">
          2018 DIY Handmade Rings, LLC All rights reserved.
        </p>
      </div>
    </footer>
  );
}
