import React from 'react';
import { useState } from 'react'
import { RadioGroup} from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OptionsForm = ({selectedBooking}) => {
  const [selectedSize, setSelectedSize] = useState(selectedBooking.sizes[0])

  return (
    <form className="mt-10">

      {/* Sizes */}
      <div className="mt-10">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-medium text-gray-900">Number of participants</h3>
          {/* <a href="/event_sizing_guide" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">Size guide</a> */}
        </div>

        <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
          <RadioGroup.Label className="sr-only"> Choose a size </RadioGroup.Label>
          <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
            {selectedBooking.sizes.map((size) => (
              <RadioGroup.Option
                key={size.name}
                value={size}
                disabled={!size.inStock}
                className={({ active }) =>
                  classNames(
                    'cursor-pointer bg-white text-gray-900 shadow-sm',
                    active ? 'ring-2 ring-indigo-500' : '',
                    'group relative flex items-center justify-center rounded-md border py-3 px-4 text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <RadioGroup.Label as="span">{size.name}</RadioGroup.Label>
                    <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-indigo-500' : 'border-transparent',
                          'pointer-events-none absolute -inset-px rounded-md'
                        )}
                        aria-hidden="true"
                      />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

      </div>

      {/* <button
        type="submit"
        className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        Add to bag
      </button> */}
    </form>
  );
};

export default OptionsForm;