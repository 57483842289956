import React from 'react';

const DateNightDescription = () => {
  return (
    <div className='mt-6'>
      <p className='mt-2'>Bored of dinner and a movie? Come to DIY Rings studio for your next date night.</p>
      <h2 className="text-lg font-bold mt-4">What you'll do:</h2>
      <p className='mt-2'>
        We'll start with an orientation & go over some safety protocols as you'll be handling hand tools. Then you'll have a chance to look through samples of finished rings while we talk about what you want to make. You can choose to follow a template or you can create something totally new. I'll be with you, guiding and teaching as you take a raw piece of metal and shape it into a gorgeous piece of jewelry.
      </p>
      <p className='mt-2'>
        When you walk away you'll have a ring, but you'll also have an experience with a story to tell. People will compliment your jewelry and you'll be able to say, "Really? Thanks! I made it." Or, "My partner made that for me!"
      </p>
      
      <h2 className="text-lg font-bold mt-4">Other things to note:</h2>
      <ul>
        <li>Come prepared to get your hands dirty and dress appropriately.</li>
        <li>Don’t forget to wear your reading glasses if you can’t see close.</li>
        <li>You shouldn't drink alcohol during this activity.</li>
        <li>Reach out with questions.</li>
        <li>Price does not include gold or precious stones.</li>
      </ul>
    </div>
  );
};

export default DateNightDescription;
