import React from 'react';

const CoinRingsDescription = () => {
  return (
    <div className='mt-6'>
      <p className='mt-2'>Commemorate your special event with a silver proof coin ring. Coin rings are one of the easiest rings to make and so they are great for large parties. Our studio can accommodate up to six guests at a time, but we have a mobile coin ring making station, so we can come to your next corporate event, family reunion, wedding reception etc. Your guests will get to make their own ring with the year of the quarter on it to commemorate the event. What a cool party favor!</p>
      <h2 className="text-lg font-bold mt-4">What you'll do:</h2>
      <p className='mt-2'>
        This is a hands-on class where you and will be taking silver proof quarters and shaping them into coin rings. I’ll provide the quarters. If there's a special year you'd like to commemorate, let me know ahead of time and I'll see if I can get quarters with that date. We’ll spend a short time going over safety and then I will guide you through the process of making your own ring. By the end of the session each guest will have a commemorative coin ring to take home with them.
      </p>
      
      <h2 className="text-lg font-bold mt-4">Other things to note:</h2>
      <ul>
        <li>Come prepared to get your hands dirty and dress appropriately.</li>
        <li>Don’t forget to wear your reading glasses if you can’t see close.</li>
        <li>You shouldn't drink alcohol during this activity.</li>
        <li>Reach out with questions.</li>
        <li>Price does not include gold or precious stones.</li>
      </ul>
    </div>
  );
};

export default CoinRingsDescription;
