import BookingPage from "./BookingPage";

const cost = 150;
const WeddingBandClass = {
  name: "Wedding Bands",
  price: `$${cost} per person`,
  priceSubtext:
    "Pricing depends on the materials used and how many sessions needed. Call for more info.",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Home", href: "/" },
    { id: 2, name: "Book a Class", href: "/class" },
  ],
  sizes: [
    { name: "1", inStock: true },
    { name: "2", inStock: true },
  ],
  images: [
    {
      src: "/assets/images/wedding_band/IMG_1294.jpg",
      alt: "Description for image 1",
      order: 1,
      inStock: true,
    },
    {
      src: "/assets/images/wedding_band/IMG_1301.jpg",
      alt: "Description for image 2",
      order: 2,
      inStock: true,
    },
    {
      src: "/assets/images/wedding_band/IMG_1388.jpg",
      alt: "Description for image 3",
      order: 3,
      inStock: true,
    },
    {
      src: "/assets/images/wedding_band/IMG_1437.jpg",
      alt: "Description for image 4",
      order: 4,
      inStock: true,
    },
    {
      src: "/assets/images/wedding_band/shelly&.jpg",
      alt: "Description for image 5",
      order: 5,
      inStock: true,
    },
  ],
  reviews: {
    average: 4,
    featured: [1, 2],
    all: [
      {
        id: 1,
        rating: 5,
        content: `
          Dan did a great job of walking through the process of making the rings that my wife and I had chosen for our class! I loved how he explained why we were doing before we did it and then gave us the space to figure out how to do it on our own.
        `,
        author: "Mathew",
      },
      {
        id: 2,
        rating: 5,
        content: `
          Dan's workshop is a real gem. As one would expect from a well polished machinist such as him, there is an high attention to detail during the whole class. Health and safety is definitely not overlooked, and Dan makes sure each tool and step is well understood and that proper safety precautions are in place from the get go. We loved the experience and our rings worked out great. Thanks again :)
        `,
        author: "Michel",
      },
      {
        id: 3,
        rating: 5,
        content: `
          i went there to make a promise ring for me and my girlfriend and the experience was awesome. its something we will never forget, Dan is an wonderful guy who guide us thru all the process for getting our rings done. And even we were very impressed when he told us about how he started making rings and helping others. we definitely recommend this place 100%. - karla & Seokyoung
        `,
        author: "Seokyoung",
      },
    ],
  },
};

const EngagementRingClass = {
  name: "Engagement Rings",
  price: `$${cost} per person`,
  priceSubtext:
    "Pricing depends on the materials used and how many sessions needed. Call for more info.",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Home", href: "/" },
    { id: 2, name: "Book a Class", href: "/class" },
  ],
  sizes: [
    { name: "1", inStock: true },
    { name: "2", inStock: true },
  ],
  images: [
    {
      src: "/assets/images/engagement_ring/20190303_132741_HDR.jpg",
      alt: "Description for image 1",
      order: 1,
      inStock: true,
    },
    {
      src: "/assets/images/engagement_ring/Air bnb pics dan & kyle.jpg",
      alt: "Description for image 2",
      order: 2,
      inStock: true,
    },
    {
      src: "/assets/images/engagement_ring/Anne&Dan Wedding (264)_lilies vertical.jpg",
      alt: "Description for image 3",
      order: 3,
      inStock: true,
    },
    {
      src: "/assets/images/engagement_ring/IMG_1272.jpg",
      alt: "Description for image 4",
      order: 4,
      inStock: true,
    },
    {
      src: "/assets/images/engagement_ring/IMG_2038.jpg",
      alt: "Description for image 5",
      order: 5,
      inStock: true,
    },
    {
      src: "/assets/images/engagement_ring/IMG_2054.jpg",
      alt: "Description for image 6",
      order: 6,
      inStock: true,
    },
  ],
  reviews: {
    average: 4,
    featured: [1, 2],
    all: [
      {
        id: 1,
        rating: 5,
        content: `
        Great teacher, wonderful studio and an unforgettable experience. The handmade rings and jewelry you make yourself with Dan’s mentorship will surely put anything at retail jewelry stores to shame! You can create anything from a professional looking wedding band or an engagement ring, to a promise ring for someone special in your life. Very memorable date night experience recommended for anyone. Whether you just started dating, you’re about to propose or celebrating a wedding anniversary; you’ll leave Dan’s studio with jewelry and memories worth taking with you for a lifetime.
        `,
        author: "J",
      },
      {
        id: 2,
        rating: 5,
        content: `
        My new fiancé and I had a wonderful time making our own wedding rings with Dan! It was such a special experience and something we’ll cherish always. Dan is so passionate about helping couples create something unique and getting to have a hand in one another’s rings was really fun and exciting! We can’t stop gushing about & showing off our rings. Highly recommend this workshop!
        `,
        author: "Gina",
      },
      {
        id: 3,
        rating: 5,
        content: `
          What an amazing, unique experience! It was so much fun to create rings that are very personal and attractive. I will wear mine from now on because it looks great and also reminds me of the very special time my fiancé and I had making them! Dan is a great teacher and made it fun to be there!! I would not hesitate to bring other family and friends here. I also highly recommend this experience for couples wanting a very special pair of wedding rings they can make for each other! Super cool stuff!!
        `,
        author: "Jennifer",
      },
    ],
  },
};

const DateNightClass = {
  name: "Date Night",
  price: `$${cost} per person`,
  priceSubtext:
    "Pricing depends on the materials used and how many sessions needed. Call for more info.",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Home", href: "/" },
    { id: 2, name: "Book a Class", href: "/class" },
  ],
  sizes: [
    { name: "1", inStock: true },
    { name: "2", inStock: true },
  ],
  images: [
    {
      src: "/assets/images/date_night/Air bnb pics dan Sarah & David w blowtorch.jpg",
      alt: "Description for image 4",
      order: 4,
      inStock: true,
    },
    {
      src: "/assets/images/date_night/Air bnb pics david.jpg",
      alt: "Description for image 5",
      order: 5,
      inStock: true,
    },
    {
      src: "/assets/images/date_night/Air bnb pics Sarah kissing David.jpg",
      alt: "Description for image 6",
      order: 6,
      inStock: true,
    },
  ],
  reviews: {
    average: 4,
    featured: [1, 2],
    all: [
      {
        id: 1,
        rating: 5,
        content: `
        Wow! I think I signed up for this thinking very simply, “oh - this will be a fun and sweet surprise little workshop because my husband is interested in metalsmithing,” but never would I have guessed how bonding of an experience this would be or how excited at each step of the way we would be while building these rings! Dan makes a warm welcoming environment with all the tools and a wealth of knowledge! I can tell he really loves what he does and has a passion for metal craft. We had the best time and got to walk away with 100% unique hand made by us (!) wedding bands. This is an experience worth traveling for! Thanks Dan!
        `,
        author: "Rosemary",
      },
      {
        id: 2,
        rating: 5,
        content: `
        This is a MUST DO. my husband and I came in not knowing what to expect and it was amazing! We made rings for each other that we will wear forever. Such a sentimental awesome experience! Dan knows his stuff and wears his heart on his sleeve. You will be able to tell very early on how passionate and knowledgeable he is about the subject. One of the best experiences we’ve had together.
        `,
        author: "Page",
      },
      {
        id: 3,
        rating: 5,
        content: `
        My wife and I had a great time making rings. The experience was all we hoped for and more. We would highly recommend this to anyone interested.
        `,
        author: "Tad And Andrea",
      },
      {
        id: 4,
        rating: 5,
        content: `
        This is an incredible experience, where you are led through the journey of selecting and creating your own rings. My boyfriend and I were able to make each others rings which was so much fun. Dan is a wonderful instructor who's truly passionate about partnering with people and teaching them how to craft something which they can keep forever.
        Loved everything about this experience! Thank you so much Dan!
          `,
        author: "Amanda",
      },
      {
        id: 5,
        rating: 5,
        content: `
        This was one of the most rewarding and unique date night experiences we’ve ever had! You go from raw materials to a beautiful and long lasting ring, and we can’t praise this class and Dan, the instructor, enough. It had me saying “I wish I could do this every day as my job.” I can’t wait to take another one soon!
        `,
        author: "Jefferson",
      },
      {
        id: 6,
        rating: 5,
        content: `
        My husband and I had a great time making matching rings. Dan is great! He is patient and explains all of the steps really well. We had a great night and highly recommend this experience. Do it!
        `,
        author: "Becky",
      },
      {
        id: 7,
        rating: 5,
        content: `
        Great Experience! Dan was very knowledgeable & was very thorough at explaining the creative process. My girlfriend and I had a great time making our rings that turned out so good!
        `,
        author: "Leuo Jr",
      },
      {
        id: 8,
        rating: 5,
        content: `
        It’s so much fun! Dan really gives you a personal feel and helps you create something beautiful! We’re going again, we loved it so much!
        `,
        author: "Olivia",
      },
    ],
  },
};

const GirlsNightClass = {
  name: "Girl's Night",
  price: `$${cost} per person`,
  priceSubtext:
    "Pricing depends on the materials used and how many sessions needed. Call for more info.",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Home", href: "/" },
    { id: 2, name: "Book a Class", href: "/class" },
  ],
  sizes: [
    { name: "1", inStock: true },
    { name: "2", inStock: true },
  ],
  images: [
    {
      src: "/assets/images/girls_night/Air bnb pics ciss w dan2.jpg",
      alt: "Description for image 1",
      order: 1,
      inStock: true,
    },
    {
      src: "/assets/images/girls_night/Air bnb pics ciss w dan.jpg",
      alt: "Description for image 2",
      order: 2,
      inStock: true,
    },
    {
      src: "/assets/images/girls_night/Air bnb pics girls picking rings.jpg",
      alt: "Description for image 3",
      order: 3,
      inStock: true,
    },
    {
      src: "/assets/images/girls_night/Air bnb pics Sarah w blowtorch.jpg",
      alt: "Description for image 4",
      order: 4,
      inStock: true,
    },
  ],
  reviews: {
    average: 4,
    featured: [1, 2],
    all: [
      {
        id: 1,
        rating: 5,
        content: `
        This was a great way to spend an evening with friends! We are already planning a return trip and are very excited about our ideas for next time! Dan was so great and juggled helping each of us the entire time!
        `,
        author: "Josi",
      },
      {
        id: 2,
        rating: 5,
        content: `
        Dan was very knowledgeable and helped me through each step of the jewelry making process. He made sure to ask what I wanted out of the experience, set expectations and definitely delivered beyond anything I could imagine! Would recommend!
        `,
        author: "Prarthana",
      },
      {
        id: 3,
        rating: 5,
        content: `
        I surprised my wife with this experience for her birthday. I thought it would be fun but wasn't expecting to be so amazing. Dan is a journeyman machinist and it shows! For those of you that don't know what that means, a journeyman machinist not only knows they trade but has been an apprentice and knows how to apprentice others. Dan is a great teacher. He shares not only the how to do things but the why as well. He has a great sense of humor and you feel right at home quickly. You can make a ring in one visit or plan to make something more complicated and come back for another session (we opted to do that). This would be a super experience for wedding rings or even a bunch of friends who want to commemorate a special occasion. (Dan can have up to 6 folks at a time). If you like being creative, learning new things, and being in a great environment that's friendly and fun I'd recommend Dan's DIY ring making 100%.
        `,
        author: "Richard",
      },
      {
        id: 4,
        rating: 5,
        content: `
        We had a great time making rings. It was an excellent date night to remember. Dan was patient and accommodating. He had sodas, safety equipment, and anything we needed to enjoy our evening.
        `,
        author: "Jason",
      },
      {
        id: 5,
        rating: 5,
        content: `
        This experience was a once in a lifetime chance. My boyfriend and I came to Salt Lake City to visit and decided it would be a fun date nite to make rings for each other. Dan created such a fun experience. He took the time to help us personalize each other’s rings. Our rings turned out so good and created the best souvenir we could have ever imagined to have. 10/10 would do it again.
        `,
        author: "Brooke",
      },
      {
        id: 6,
        rating: 5,
        content: `
        Making rings at Dan’s was a highlight of our trip! I didn’t want to have someone make a ring while I watched but to actually participate. That’s exactly how it went with Dan explaining the process but we were able to do much of it ourselves. We took longer than the listed amount of time and Dan never rushed us or made us feel like we better hurry. Just a great experience that I would recommend for anyone!
        `,
        author: "Katie",
      },
      {
        id: 7,
        rating: 5,
        content: `
        My daughter and I had such a great time making jewelry in Dan’s studio! I made really neat earrings and my daughter made 2 Möbius rings….we both love our new treasures! Dan is very personable and very thorough and best of all, he let us do the work which made it fun and interesting! We liked it so much, we are going back with our husbands in December!
        `,
        author: "Patty",
      },
    ],
  },
};

let bookingOptions = [
  WeddingBandClass,
  EngagementRingClass,
  GirlsNightClass,
  DateNightClass,
];

export default function Example() {
  return (
    <BookingPage bookingOptions={bookingOptions} pageTitle={"Book a Class"} />
  );
}
