import React from 'react';

const EngagementRingsDescription = () => {
  return (
    <div className='mt-6'>
      <p className='mt-2'>Want a big romantic gesture? Make their engagement ring by hand.</p>
      <h2 className="text-lg font-bold mt-4">What you'll do:</h2>
      <p className='mt-2'>
        We'll start with a consultation where you choose what materials you want to use and discuss the design you have in mind. You can choose to follow a template or you can create something totally new. Pricing will depend on the materials and how many classes it takes to complete your project. I'll be with you, guiding and teaching as you take a raw piece of metal and shape it into an engagement ring they’ll enjoy showing off. There’s something special about wrapping their finger in a strand of metal shaped by your own hands.
      </p>
      
      <h2 className="text-lg font-bold mt-4">Other things to note:</h2>
      <ul>
        <li>Come prepared to get your hands dirty and dress appropriately.</li>
        <li>Don't forget to wear your glasses.</li>
        <li>You shouldn't drink alcohol during this activity.</li>
        <li>Reach out with questions.</li>
        <li>Price does not include gold or precious stones.</li>
      </ul>
    </div>
  );
};

export default EngagementRingsDescription;
