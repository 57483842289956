// https://tailwindui.com/components/ecommerce/components/product-lists  Card with full details

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

export default function Example({ products }) {
  console.log(products);
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {products.map((product) => (
            <div
              key={product.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                <img
                  // src={product.images[0].src}
                  src={`${process.env.PUBLIC_URL}${product.images[0].src}`}
                  alt={`${process.env.PUBLIC_URL}${product.images[0].alt}`}
                  // alt={product.images[0].alt}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={product.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </a>
                </h3>
                <p className="text-sm text-gray-500">{product.description}</p>
                <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500">
                    {product.options}
                  </p>
                  <p className="text-base font-medium text-gray-900">
                    {product.price}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// function Jewelry({ products }) {
//   return (
//     <div>
//       <h1>Jewelry</h1>
//       {products.length > 0 ? (
//         <ul>
//           {products.map((product) => (
//             <li key={product.id}>
//               <Link to={`/jewelry/${product.id}`}>
//                 <img
//                   src={`${process.env.PUBLIC_URL}${product.src}`}
//                   alt={product.title}
//                 />
//                 <h2>{product.title}</h2>
//               </Link>
//             </li>
//           ))}
//         </ul>
//       ) : (
//         <div>Loading products...</div>
//       )}
//     </div>
//   );
// }

// export default Jewelry;
