import React from 'react';

const CalltoBook = () => {
  return (
    <div className='pt-6'>
      <p className="text-lg text-gray-600 mb-3">
        Call to Book Now{' '}
        <span id="phoneNumber" className="text-indigo-600">
          801-497-1390
        </span>
      </p>
      <p className="text-lg text-gray-600 mb-6">
        or email{' '}
        <span className='text-indigo-600'>
         DIYrings@gmail.com
        </span>
      </p>
      <p className="text-lg text-gray-600">
        * Discounts Availble
      </p>
    </div>
  );
};

export default CalltoBook;