import React, { useState, useEffect } from 'react';

const ImageGallery = ({ images }) => {
  const [numImagesToShow, setNumImagesToShow] = useState(1);
  const [startIndex, setStartIndex] = useState(0);

  const sortedImages = [...images].sort((a, b) => a.order - b.order);
  const [maxImageHeight, setMaxImageHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) {
        setNumImagesToShow(3);
      } else {
        setNumImagesToShow(1);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const prevImage = () => {
    setStartIndex((prevStartIndex) =>
      prevStartIndex === 0 ? sortedImages.length - numImagesToShow : prevStartIndex - 1
    );
  };

  const nextImage = () => {
    setStartIndex((prevStartIndex) =>
      prevStartIndex === sortedImages.length - numImagesToShow ? 0 : prevStartIndex + 1
    );
  };

  return (
    <div>
      <div className="mx-auto mt-6 max-w-2xl sm:px-6 grid-cols-1 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
        {sortedImages.slice(startIndex, startIndex + numImagesToShow).map((image, index) => (
          <a key={index} href={image.src} target="_blank" rel="noopener noreferrer">
            <div className="rounded-lg p-2 lg:gap-x-8 flex items-center justify-center" style={{ height: '466px' }}>
              <img src={image.src} alt={image.alt} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </div>
          </a>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <button onClick={prevImage} className="bg-gray-200 p-2 rounded mr-2">
          &lsaquo;
        </button>
        <button onClick={nextImage} className="bg-gray-200 p-2 rounded ml-2">
          &rsaquo;
        </button>
      </div>
    </div>
  );
};

export default ImageGallery;
