import React from 'react';

const GirlsNightDescription = () => {
  return (
    <div className='mt-6'>
      <p className='mt-2'>Looking for a fun group activity? Come to DIY Rings studio! Get five of your friends to join you and your tuition is free!</p>
      <h2 className="text-lg font-bold mt-4">What you'll do:</h2>
      <p className='mt-2'>
        This is a hands-on class where you and up to five friends will be taking metal and shaping it into jewelry. I’ll provide enough silver and/or copper for each guest to make a ring or a set of earrings. We’ll spend a short time going over safety and then you’ll have to decide what you want to make. I’ll provide examples of already made jewelry to use as templates if guests need ideas. By the end of the session each guest will have a piece of handmade jewelry to take home with them. People will compliment your jewelry and you'll be able to say, "Really? Thanks! I made it myself.”
      </p>
      
      <h2 className="text-lg font-bold mt-4">Other things to note:</h2>
      <ul>
        <li>Come prepared to get your hands dirty and dress appropriately.</li>
        <li>Don’t forget to wear your reading glasses if you can’t see close.</li>
        <li>You shouldn't drink alcohol during this activity.</li>
        <li>Reach out with questions.</li>
        <li>Price does not include gold or precious stones.</li>
      </ul>
    </div>
  );
};

export default GirlsNightDescription;
