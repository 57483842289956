import "./App.css";
import "./tailwind.css"; // Import Tailwind CSS
import "./index.css";    // Import your index CSS
import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import BookEvent from "./pages/BookEvent"
import BookClass from "./pages/BookClass"
import Jewelry from "./pages/Jewelry";
import ProductDetails from "./pages/ProductDetails";
import Info from "./pages/Info";

function App() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/data/products.json`)
    // fetch("http://50.18.3.245:3000/products")  // Update URL to your backend server
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.products);
      });
  }, []);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/jewelry" element={<Jewelry products={products} />} />
          <Route path="/jewelry/:id" element={<ProductDetails />} />
          <Route path="/event" element={<BookEvent />} />
          <Route path="/class" element={<BookClass />} />
          {/* <Route path="/event_sizing_guide" element={<Info />} /> */}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
export default App;
