import BookingPage from "./BookingPage";

const cost = 150;
const CoinRingClass = {
  name: "DIY Coin Rings",
  price: `$${cost} per person`,
  priceSubtext: "",
  href: "#",
  breadcrumbs: [
    { id: 1, name: "Home", href: "/" },
    { id: 2, name: "Book an Event", href: "/class" },
  ],
  sizes: [
    { name: "1", inStock: true },
    { name: "2", inStock: true },
    // fix me
  ],
  // images wrong, fix me
  images: [
    {
      src: "/assets/images/coin_class/Silver-Rings (17 of 98).jpg",
      alt: "Instructor Dan showing how to punch out the hole in the center of the ring",
      order: 2,
      inStock: true,
    },
    {
      src: "/assets/images/coin_class/Silver-Rings (48 of 98).jpg",
      alt: "Sizing the ring",
      order: 4,
      inStock: true,
    },
    {
      src: "/assets/images/coin_class/Silver-Rings (69 of 98).jpg",
      alt: "Expanding the ring",
      order: 5,
      inStock: true,
    },
    {
      src: "/assets/images/coin_class/Silver-Rings (98 of 98).jpg",
      alt: "Finished rings on a circle of hands",
      order: 1,
      inStock: true,
    },
    {
      src: "/assets/images/coin_class/Silver-Rings (43 of 98).jpg",
      alt: "Heating the ring with a blowtorch to temper or anneal the ring",
      order: 3,
      inStock: true,
    },
    {
      src: "/assets/images/coin_class/Silver-Rings (5 of 98).jpg",
      alt: "Tools used to make the rings",
      order: 6,
      inStock: true,
    },
    {
      src: "/assets/images/coin_class/Silver-Rings (89 of 98).jpg",
      alt: "Polishing the ring with a DIY ring wood sign",
      order: 7,
      inStock: true,
    },
  ],
  reviews: {
    average: 4,
    featured: [1, 2],
    all: [
      {
        id: 1,
        rating: 5,
        content: `
        This experience was FANTASTIC! Dan and his wife Annie helped support us for a private event, we had a ton of guests make amazing rings out of silver quarters and everyone had SO MUCH FUN! Also, we all get the coolest personal takeaway to take home! I would highly recommend him, either in his studio or for a special event. 20/10 would do it again!
        `,
        author: "Genevieve",
      },
      {
        id: 2,
        rating: 5,
        content: `
        Incredibly fun group activity. Dan was knowledgeable, patient, and did an excellent job with time management to ensure all our skill levels were addressed and we were able to complete the rings nearly at the same time. Highly recommend!
        `,
        author: "Marites",
      },
      {
        id: 3,
        rating: 5,
        content: `
        The experience was amazing! Dan really enjoys what he does. He is very good at making the experience special and you definitely learn a lot about what goes into it. I would recommend this class to anyone.
        `,
        author: "Nick",
      },
      {
        id: 3,
        rating: 5,
        content: `
        Dan is a wonderful person who is willing to share his passion, love, and knowledge about jewelry making. He made us feel very welcomed and we really enjoyed the experience. This is a must do and we highly recommend this experience. We absolutely love our rings.
        `,
        author: "Zeinab",
      },
    ],
  },
};

let bookingOptions = [CoinRingClass];

export default function Example() {
  return (
    <BookingPage bookingOptions={bookingOptions} pageTitle={"Book an Event"} />
  );
}
